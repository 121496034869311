import { defineMessages } from "react-intl";

const messages = defineMessages({
    deleteQueryTooltip: {
        id: "uploadPage.dataRepositoryPage.settingsPage.deleteQueryTooltip",
        defaultMessage: "Delete this query (not data)",
    },
    copy: {
        id: "uploadPage.dataRepositoryPage.settingsPage.copy",
        defaultMessage: "Copy ID to clipboard",
    },
    selectDeleteQuery: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.selectDeleteQuery",
        defaultMessage: "Select delete query",
    },
    manageDeleteQueries: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.mangageDeleteQueries",
        defaultMessage: "Manage queries",
    },
    newConfig: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.newConfig",
        defaultMessage: "New delete query",
    },
    deleteHeader: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.deleteHeader",
        defaultMessage: "Delete data rows by query to avoid duplicates",
    },

    run: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.run",
        defaultMessage: "Run delete query",
    },
    id: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.id",
        defaultMessage: "Id: {id}",
    },
    relativeDateHelpText: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.relativeDateHelpText",
        defaultMessage: "If field {fieldName} is from the last {numberOfPeriods} {periodGranularity}(s)",
    },
    dateFormat: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.dateFormat",
        defaultMessage: "Format: {dateFormat}",
    },
    fieldValueHelpText: {
        id: "uploadPage.dataRepositoryPage.deleteInRangeUploadStep.fieldValueHelpText",
        defaultMessage: "If field {fieldName} equals {value}",
    },
});

export default messages;
