import { defineMessages } from "react-intl";

const messages = defineMessages({
    confirm: {
        id: "dataManagement.components.UniqueIdentifierCOnfigurationForm.confirm",
        defaultMessage: "Confirm",
    },
    helpText: {
        id: "dataManagement.components.helpText",
        defaultMessage: "Select a field or a combination of fields that can uniquely identify a row of data.",
    },
    select: {
        id: "dataManagement.components.select",
        defaultMessage: "Select Fields",
    },
    name: {
        id: "dataManagement.components.name",
        defaultMessage: "Name",
    },
    cancel: {
        id: "dataManagement.components.cancel",
        defaultMessage: "Cancel",
    },
    namePlaceholder: {
        id: "dataManagement.components.namePlaceholder",
        defaultMessage: "{repository} duplicate identifier configuration",
    },
    replaceOrIgnoreTooltip: {
        id: "dataManagement.components.replaceOrIgnoreTooltip",
        defaultMessage:
            "YES will replace matches with the newest import. Setting to NO will ignore any new data that matches the duplicate identification criteria.",
    },
    replaceOrIgnoreHelper: {
        id: "dataManagement.components.replaceOrIgnoreHelper",
        defaultMessage: "Replace matching data rows with newest import?",
    },
    checkbuttonTitle: {
        id: "dataManagement.components.checkbuttonTitle",
        defaultMessage: "I know what I'm doing",
    },
    checkbuttonTextCreate: {
        id: "dataManagement.components.checkbuttonTextCreate",
        defaultMessage:
            "Rows with identical values in the selected fields will be considered duplicates and will be automatically deleted.",
    },
    checkbuttonTextDelete: {
        id: "dataManagement.components.checkbuttonTextDelete",
        defaultMessage: "No duplicate data will be removed.",
    },
});

export default messages;
