/* eslint-disable @typescript-eslint/no-unsafe-return */
import localeDataEnUS from "./en-US.json";
import localeDataNbNO from "./nb-NO.json";

const getRawMessages = (locale: string): Record<string, { defaultMessage: string }> => {
    if (locale === "nb-NO") return localeDataNbNO;
    return localeDataEnUS;
};

export const getMessages = (locale: string) => {
    const rawMessages = getRawMessages(locale);
    return Object.entries(rawMessages).reduce((acc, [key, message]) => {
        acc[key] = message.defaultMessage;
        return acc;
    }, {} as Record<string, string>);
};
